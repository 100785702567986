// third-party
import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ==============================|| SLICE - CATEGORY ||============================== //

const initialState = {
  error: null,
  categories: [],
  category: null
};

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET CATEGORY
    getCategorySuccess(state, action) {
      state.categories = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getCategory() {
  return async () => {
    try {
      const response = await axios.get('/admin/category');
      dispatch(slice.actions.getCategorySuccess(response.data.result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
