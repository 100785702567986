// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { UserSquare, HomeTrendUp, Story, PresentionChart, Bag2, AddSquare, MoneyChange, Check, MessageFavorite } from 'iconsax-react';
import { FaShoppingBag ,FaShippingFast,FaHandshake} from "react-icons/fa"
import { IoIosPeople } from "react-icons/io";
// icons
const icons = {
  dashboard: HomeTrendUp,
  subAdmin: UserSquare,
  mobileVan: FaShippingFast,
  store:FaShoppingBag,
  partner:FaHandshake,
  widgets: Story,
  statistics: Story,
  request: AddSquare,
  return: MoneyChange,
  checklist: Check,
  faq: MessageFavorite,
  // data: Fatrows,
  order: Bag2,
  chart: PresentionChart,
  customer:IoIosPeople,
};

// ==============================|| MENU ITEMS - WIDGETS ||============================== //

const widget = {
  id: 'Actors',
  title: '',
  icon: icons.widgets,
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="dashboard" />,
      type: 'item',
      url: '/dashboard',
      icon: icons.dashboard,
      breadcrumbs: false
    },

    {
      id: 'sub-admin',
      title: <FormattedMessage id="sub-admin" />,
      type: 'collapse',
      icon: icons.subAdmin,
      breadcrumbs: true,
      children: [{
        id: 'list',
        title: <FormattedMessage id="list" />,
        type: 'item',
        url: '/sub-admin'
      },
       {
        id: 'manage',
        title: <FormattedMessage id="manage" />,
        type: 'item',
        url: '/sub-admin/manage'
      }
    ]
    },
    // {
    //   id: 'vendor',
    //   title: <FormattedMessage id="vendor" />,
    //   type: 'item',
    //   url: '/vendor',
    //   icon: icons.SubAdmin
    // },
    {
      id: 'partner',
      title: <FormattedMessage id="partner" />,
      type: 'collapse',
      icon: icons.partner,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/vendor'
        },
        {
          id: 'manage',
          title: <FormattedMessage id="manage" />,
          type: 'item',
          url: '/vendor/manage'
        }

      ]
    },
    
    {
      id: 'in-store',
      title: <FormattedMessage id="in-store" />,
      type: 'collapse',
      // url: '/franchise',
      icon: icons.store,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/franchise'
        },
        {
          id: 'manage',
          title: <FormattedMessage id="manage" />,
          type: 'item',
          url: '/franchise/manage'
        }
      ]
    },
    {
      id: 'wheelz',
      title: <FormattedMessage id="wheelz" />,
      type: 'collapse',
      // url: '/mobile-van',
      icon: icons.mobileVan,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/wheelz'
        },
        {
          id: 'manage',
          title: <FormattedMessage id="manage" />,
          type: 'item',
          url: '/wheelz/manage'
        }
      ]
    },
    {
      id: 'customer',
      title: <FormattedMessage id="customer" />,
      type: 'collapse',
      icon: icons.customer,
      children: [
        {
          id: 'customer-list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/apps/customer/customer-list'
        },
        {
          id: 'customer-card',
          title: <FormattedMessage id="cards" />,
          type: 'item',
          url: '/apps/customer/customer-card'
        }
      ]
    },
    
    
   
   
    // {
    //   id: 'statistics',
    //   title: <FormattedMessage id="statistics" />,
    //   type: 'item',
    //   url: '/widget/statistics',
    //   icon: icons.statistics
    // },
    // {
    //   id: 'data',
    //   title: <FormattedMessage id="data" />,
    //   type: 'item',
    //   url: '/widget/data',
    //   icon: icons.data
    // }
    // {
    //   id: 'chart',
    //   title: <FormattedMessage id="chart" />,
    //   type: 'item',
    //   url: '/widget/chart',
    //   icon: icons.chart
    // }
  ]
};

export default widget;
