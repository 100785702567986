import { createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import { dispatch } from '../index';

const initialState = {
  error: null,
  states: [],
};

const slice = createSlice({
  name: 'state',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },

    getStateSuccess(state, action) {
      state.states = action.payload;
    }
  }
});

export default slice.reducer;

export const getState = () => async () => {
  try {
    const response = await axios.get('common/state/null');
    dispatch(slice.actions.getStateSuccess(response.data.result.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};
