// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Book, PasswordCheck, Next, RowVertical, CpuCharge, TableDocument, Subtitle } from 'iconsax-react';
import { FaBoxOpen,FaCartPlus } from "react-icons/fa";
import { CgNotes } from "react-icons/cg"
import { TbTruckReturn } from "react-icons/tb";

// icons
const icons = {
  formsTable: Book,
  validation: PasswordCheck,
  wizard: Next,
  layout: RowVertical,
  plugins: CpuCharge,
  reactTables: TableDocument,
  muiTables: Subtitle,    
  ecommerce:FaBoxOpen,
  order:FaCartPlus,
  request:CgNotes,
  return:TbTruckReturn,
};

// ==============================|| MENU ITEMS - FORMS & TABLES ||============================== //

const formsTables = {
  id: 'Products',
  title: <FormattedMessage id="Products" />,
  icon: icons.formsTable,
  type: 'group',
  children: [
    // {
    //   id: 'validation',
    //   title: <FormattedMessage id="forms-validation" />,
    //   type: 'item',
    //   url: '/forms/validation',
    //   icon: icons.validation
    // },
    // {
    //   id: 'wizard',
    //   title: <FormattedMessage id="forms-wizard" />,
    //   type: 'item',
    //   url: '/forms/wizard',
    //   icon: icons.wizard
    // },
    // {
    //   id: 'forms-layout',
    //   title: <FormattedMessage id="layout" />,
    //   type: 'collapse',
    //   icon: icons.layout,
    //   children: [
    //     {
    //       id: 'basic',
    //       title: <FormattedMessage id="basic" />,
    //       type: 'item',
    //       url: '/forms/layout/basic'
    //     },
    //     {
    //       id: 'multi-column',
    //       title: <FormattedMessage id="multi-column" />,
    //       type: 'item',
    //       url: '/forms/layout/multi-column'
    //     },
    //     {
    //       id: 'action-bar',
    //       title: <FormattedMessage id="action-bar" />,
    //       type: 'item',
    //       url: '/forms/layout/action-bar'
    //     },
    //     {
    //       id: 'sticky-bar',
    //       title: <FormattedMessage id="sticky-bar" />,
    //       type: 'item',
    //       url: '/forms/layout/sticky-bar'
    //     }
    //   ]
    // },
    {
      id: 'products',
      title: <FormattedMessage id="products" />,
      type: 'collapse',
      icon: icons.ecommerce,
      children: [
        // {
        //   id: 'products',
        //   title: <FormattedMessage id="products" />,
        //   type: 'item',
        //   url: '/apps/e-commerce/products'
        // },
        // {
        //   id: 'product-details',
        //   title: <FormattedMessage id="product-details" />,
        //   type: 'item',
        //   url: '/apps/e-commerce/product-details/1',
        //   breadcrumbs: false
        // },
        {
          id: 'product-list',
          title: <FormattedMessage id="product-list" />,
          type: 'item',
          url: '/apps/e-commerce/product-list',
          // breadcrumbs: false
        },
        // {
        //   id: 'add-new-product',
        //   title: <FormattedMessage id="add-new-product" />,
        //   type: 'item',
        //   url: '/apps/e-commerce/add-new-product'
        // },
        // {
        //   id: 'checkout',
        //   title: <FormattedMessage id="checkout" />,
        //   type: 'item',
        //   url: '/apps/e-commerce/checkout'
        // }
      ]
    },
    {
      id: 'request',
      title: <FormattedMessage id="request" />,
      type: 'collapse',
      icon: icons.request,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/request'
        },
        {
          id: 'return-request',
          title: <FormattedMessage id="return-request" />,
          type: 'item',
          url: '/request/return-request'
        }
      ]
    },
    {
      id: 'order',
      title: <FormattedMessage id="order" />,
      type: 'collapse',
      // url: '/order',
      icon: icons.order,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/order'
        },
        {
          id: 'product-return',
          title: <FormattedMessage id="product-return" />,
          type: 'item',
          url: '/order/return',
          breadcrumbs: true
        }
      ]
    },
    {
      id: 'return',
      title: <FormattedMessage id="return" />,
      type: 'collapse',
      // url: '/mobile-van',
      icon: icons.return,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/return'
        },
        {
          id: 'manage',
          title: <FormattedMessage id="manage" />,
          type: 'item',
          url: '/return/manage'
        }
      ]
    },
   
    // {
    //   id: 'react-tables',
    //   title: <FormattedMessage id="react-table" />,
    //   type: 'collapse',
    //   icon: icons.reactTables,
    //   children: [
    //     {
    //       id: 'rt-table',
    //       title: <FormattedMessage id="basic" />,
    //       type: 'item',
    //       url: '/tables/react-table/basic'
    //     },
    //     {
    //       id: 'rt-sorting',
    //       title: <FormattedMessage id="sorting" />,
    //       type: 'item',
    //       url: '/tables/react-table/sorting'
    //     },
    //     {
    //       id: 'rt-filtering',
    //       title: <FormattedMessage id="filtering" />,
    //       type: 'item',
    //       url: '/tables/react-table/filtering'
    //     },
    //     {
    //       id: 'rt-grouping',
    //       title: <FormattedMessage id="grouping" />,
    //       type: 'item',
    //       url: '/tables/react-table/grouping'
    //     },
    //     {
    //       id: 'rt-pagination',
    //       title: <FormattedMessage id="pagination" />,
    //       type: 'item',
    //       url: '/tables/react-table/pagination'
    //     },
    //     {
    //       id: 'rt-row-selection',
    //       title: <FormattedMessage id="row-selection" />,
    //       type: 'item',
    //       url: '/tables/react-table/row-selection'
    //     },
    //     {
    //       id: 'rt-expanding',
    //       title: <FormattedMessage id="expanding" />,
    //       type: 'item',
    //       url: '/tables/react-table/expanding'
    //     },
    //     {
    //       id: 'rt-editable',
    //       title: <FormattedMessage id="editable" />,
    //       type: 'item',
    //       url: '/tables/react-table/editable'
    //     },
    //     {
    //       id: 'rt-drag-drop',
    //       title: <FormattedMessage id="drag-drop" />,
    //       type: 'item',
    //       url: '/tables/react-table/drag-drop'
    //     },
    //     {
    //       id: 'rt-column-hiding',
    //       title: <FormattedMessage id="column-hiding" />,
    //       type: 'item',
    //       url: '/tables/react-table/column-hiding'
    //     },
    //     {
    //       id: 'rt-column-resizing',
    //       title: <FormattedMessage id="column-resizing" />,
    //       type: 'item',
    //       url: '/tables/react-table/column-resizing'
    //     },
    //     {
    //       id: 'rt-sticky-table',
    //       title: <FormattedMessage id="sticky" />,
    //       type: 'item',
    //       url: '/tables/react-table/sticky-table'
    //     },
    //     {
    //       id: 'rt-umbrella',
    //       title: <FormattedMessage id="umbrella" />,
    //       type: 'item',
    //       url: '/tables/react-table/umbrella'
    //     },
    //     {
    //       id: 'rt-empty',
    //       title: <FormattedMessage id="empty" />,
    //       type: 'item',
    //       url: '/tables/react-table/empty'
    //     }
    //   ]
    // },
    // {
    //   id: 'mui-tables',
    //   title: <FormattedMessage id="mui-table" />,
    //   type: 'collapse',
    //   icon: icons.muiTables,
    //   children: [
    //     {
    //       id: 'mui-table',
    //       title: <FormattedMessage id="basic" />,
    //       type: 'item',
    //       url: '/tables/mui-table/basic'
    //     },
    //     {
    //       id: 'mui-dense',
    //       title: <FormattedMessage id="dense" />,
    //       type: 'item',
    //       url: '/tables/mui-table/dense'
    //     },
    //     {
    //       id: 'mui-enhanced',
    //       title: <FormattedMessage id="enhanced" />,
    //       type: 'item',
    //       url: '/tables/mui-table/enhanced'
    //     },
    //     {
    //       id: 'mui-data-table',
    //       title: <FormattedMessage id="datatable" />,
    //       type: 'item',
    //       url: '/tables/mui-table/datatable'
    //     },
    //     {
    //       id: 'mui-custom',
    //       title: <FormattedMessage id="custom" />,
    //       type: 'item',
    //       url: '/tables/mui-table/custom'
    //     },
    //     {
    //       id: 'mui-fixed-header',
    //       title: <FormattedMessage id="fixed-header" />,
    //       type: 'item',
    //       url: '/tables/mui-table/fixed-header'
    //     },
    //     {
    //       id: 'mui-collapse',
    //       title: <FormattedMessage id="collapse" />,
    //       type: 'item',
    //       url: '/tables/mui-table/collapse'
    //     }
    //   ]
    // }
  ]
};

export default formsTables;
